import React from 'react';
import CalculatorLayout from 'src/layouts/CalculatorLayout';

const DocumentManagement = () => {
  return (
    <CalculatorLayout
      title="Document Management"
      backTo="/dashboard"
    >
      <main className="mt-10 px-5">
        {/* Content will be added here */}
      </main>
    </CalculatorLayout>
  );
};

export default DocumentManagement; 