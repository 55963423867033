export const DOCUMENT_MANAGEMENT = {
  CARD: {
    TITLE: 'Manage My Documents',
    DESCRIPTION: "<p><strong>Securely store and access your most important financial documents with your FitBUX Vault.</strong> Whether it's student loan screenshots, mortgage statements, or your home title, your Vault is a digital safe—always protected, always available.<br><br> Click the button below and we will email your activation credentials.</p>",
    BUTTONS: {
      UPLOAD: 'Upload/Retrieve Documents',
      CREDENTIALS: 'Create Secured Credentials',
    },
  },
  MESSAGES: {
    SUCCESS: 'An email has been sent to your registered email address with instructions to create your Vault credentials.',
    EXISTS: 'You already have a Vault account. Click Upload/Retrieve Documents to access your Vault or message us via the message center if this is an error.',
    ERROR: 'Something went wrong. Please message us via your the message center on your profile.',
  },
}; 