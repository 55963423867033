import { Box, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Card from '../Card';
import Toast from '../Toast';
import Button from '../Button';
import { DOCUMENT_MANAGEMENT } from './constants';
import { useCredentials } from './hooks/useCredentials';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    gap: '1rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
  },
});

const DocumentManagementCard = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    isLoading,
    toastOpen,
    toastMessage,
    handleCreateCredentials,
    closeToast,
    isError,
  } = useCredentials();

  return (
    <>
      <Card title={DOCUMENT_MANAGEMENT.CARD.TITLE} className="md:h-80">
        <Box className={classes.container}>
        <div dangerouslySetInnerHTML={{ __html: DOCUMENT_MANAGEMENT.CARD.DESCRIPTION }} />
          <Box className={classes.buttonContainer}>
            <Button
              variant="contained"
              fbColor="primary"
              onClick={() => window.open('https://fitbux.smartvault.com', '_blank', 'noopener,noreferrer')}
              disabled={isLoading}
            >
              {DOCUMENT_MANAGEMENT.CARD.BUTTONS.UPLOAD}
            </Button>
            <Button
              variant="outlined"
              fbColor="primary"
              onClick={handleCreateCredentials}
              disabled={isLoading}
            >
              {DOCUMENT_MANAGEMENT.CARD.BUTTONS.CREDENTIALS}
            </Button>
          </Box>
        </Box>
      </Card>

      <Toast
        open={toastOpen}
        message={toastMessage}
        onClose={closeToast}
        type={isError ? 'error' : 'success'}
      />
    </>
  );
};

export default DocumentManagementCard; 