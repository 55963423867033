import { useState } from 'react';
import { createDocumentClient } from '../../../apiService/modules/documentmanagement';
import { DOCUMENT_MANAGEMENT } from '../constants';

interface UseCredentialsReturn {
  isLoading: boolean;
  toastOpen: boolean;
  toastMessage: string;
  handleCreateCredentials: () => Promise<void>;
  closeToast: () => void;
  isError: boolean;
}

export const useCredentials = (): UseCredentialsReturn => {
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const closeToast = () => {
    setToastOpen(false);
  };

  const handleCreateCredentials = async () => {
    setIsLoading(true);
    
    try {
      const result = await createDocumentClient();
      
      if (result.status === 201) {
        setToastMessage(DOCUMENT_MANAGEMENT.MESSAGES.SUCCESS);
        setIsError(false);
      } else if (result.status === 409) {
        setToastMessage(DOCUMENT_MANAGEMENT.MESSAGES.EXISTS);
        setIsError(true);
      } else {
        setToastMessage(DOCUMENT_MANAGEMENT.MESSAGES.ERROR);
        setIsError(true);
      }
      
      setTimeout(() => {
        setToastOpen(true);
      }, 0);
    } catch (error) {
      setToastMessage(DOCUMENT_MANAGEMENT.MESSAGES.ERROR);
      setIsError(true);
      setToastOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    toastOpen,
    toastMessage,
    handleCreateCredentials,
    closeToast,
    isError,
  };
}; 