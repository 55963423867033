import React from 'react';
import { Box, Snackbar, makeStyles } from '@material-ui/core';
import { Slide } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  errorAlert: {
    backgroundColor: '#fff5f5',
    color: '#2d3748',
    padding: theme.spacing(2),
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.shadows[6],
  },
  successAlert: {
    backgroundColor: '#f0fff4', // Light green background
    color: '#2f855a', // Dark green text
    padding: theme.spacing(2),
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    boxShadow: theme.shadows[6],
  },
  errorIcon: {
    color: '#e53e3e',
    marginRight: theme.spacing(1),
  },
  successIcon: {
    color: '#38a169', // Green color for success icon
    marginRight: theme.spacing(1),
  },
}));

const SlideTransition = (props: any) => {
  return <Slide {...props} direction="left" />;
};

interface ToastProps {
  open: boolean;
  message: string;
  onClose: () => void;
  type: 'error' | 'success'; // New prop to determine the type of toast
}

const Toast: React.FC<ToastProps> = ({ open, message, onClose, type }) => {
  const classes = useStyles();

  return (
    <Snackbar 
      open={open} 
      autoHideDuration={3000} 
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      TransitionComponent={SlideTransition}
      TransitionProps={{ timeout: 300 }}
    >
      <Box className={type === 'error' ? classes.errorAlert : classes.successAlert}>
        {type === 'error' ? (
          <>
            <ErrorIcon className={classes.errorIcon} />
            {message}
          </>
        ) : (
          <>
            <CheckCircleIcon className={classes.successIcon} />
            {message}
          </>
        )}
      </Box>
    </Snackbar>
  );
};

export default Toast; 